html.md,
html.ios {
  --ion-default-font: "Poppins", sans-serif;
}
.book-guru-logo {
  width: 260px;
  margin: 9% auto 40px;
  display: block;
}
// header
.header-md:after {
  background-image: none;
}
ion-header {
  // --ion-toolbar-background: #fdbe21;
  --ion-toolbar-background: #fec027;
  ion-toolbar {
    --padding-top: 4px;
    --padding-bottom: 4px;
    --padding-start: 4px;
    --padding-end: 4px;
    --min-height: 56px;
    --color: #fff;
  }

  ion-title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  ion-menu-toggle,
  ion-menu-button {
    color: #fff;
    --color: #fff;
    ion-button {
      --color: #fff;
    }
  }
  ion-back-button {
    button {
      --margin-end: 0;
      --icon-margin-end: 0;
      --icon-margin-start: 0;
      --icon-padding-end: 0;
      --icon-padding-start: 0;
      --min-width: 34px;
    }
  }
  .sc-ion-back-button-ios-h {
    color: #fff;
    --color: #fff;
    font-size: 15px;
  }
} // ion-header
.header-ios ion-toolbar:last-child {
  --border-width: 0 !important;
}
// sidemenu
.bookguru-sidemenu-header {
  ion-header {
    --ion-toolbar-background: transparent;
    .profile-img-sidemenu-wrap {
      height: 100px;
      width: 100px;
      min-width: 100px;
      border-radius: 50%;
      border: 3px solid #fff;
      overflow: hidden;
      // margin-right: 10px;
      position: relative;
      display: block;
      margin: 35px auto 15px;

      .profile-img-sidemenu {
        height: 100px;
        width: 100px;
        min-width: 100px;
        border-radius: 50%;
        object-fit: contain;
        object-position: center;
        margin-left: -3px;
      }

      .profile-img-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.55);
        // color: #fdbe21;
        color: #fec027;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-weight: 700;
        // letter-spacing: 4px;
        text-transform: uppercase;
      }

      .object_cover {
        object-fit: cover;
        margin-left: 0;
      }
    }
    // .profile-img-sidemenu {
    //   height: 100px;
    //   width: 100px;
    //   border-radius: 50%;
    //   border: 3px solid #fff;
    //   -o-object-fit: cover;
    //   object-fit: cover;
    //   -o-object-position: center;
    //   object-position: center;
    //   display: block;
    //   margin: 35px auto 15px;
    // }
  }
  --background: #333;

  ion-content {
    --background: transparent;
    ion-list {
      --ion-background-color: transparent;
    }
    ion-item {
      --min-height: unset;
      --color: #fff;
      --inner-border-width: 0;
      text-align: left;
      font-size: 15px;
      letter-spacing: 0.5px;
      padding-left: 60px;
      ion-label {
        margin-top: 6px;
        margin-bottom: 5px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
  ion-footer {
    --ion-toolbar-background: transparent;
    margin-bottom: 10px;
    ion-title {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      letter-spacing: 0.5px;
      text-align: center;
      a {
        display: inline-block;
        margin-left: 7px;
        vertical-align: middle;
        img {
          height: 22px;
          margin-top: 1px;
        }
      }
    }
  }
  .footer-md:before {
    background-image: none;
  }
}
.footer-ios ion-toolbar:first-child {
  --border-width: 0;
}
// form css
.form-input-wrap {
  width: 100%;
  max-width: 332px;
  margin: 20px auto;
  position: relative;
  ion-item {
    --padding-start: 45px;
    --padding-end: 12px;
    --border-width: 1px;
    --inner-border-width: 0;
    --ion-border-color: #fde8b3;
    --border-radius: 12px;
    font-size: 15px;
    --highlight-height: 0;
    --inner-padding-end: 0;
    letter-spacing: 0.3px;
    ion-label {
      color: #050706 !important;
      margin-top: -5px;
    }
    ion-input {
      font-weight: 500;
      --min-height: 55px;
    }
    .sc-ion-input-ios-h {
      --padding-end: 0;
    }
  }
  .item-has-focus,
  .item-has-value {
    --min-height: 55px;
    .label-floating {
      margin-top: 10px;
      margin-bottom: -9px;
      -webkit-transition: -webkit-transform 0.4s ease-in-out;
      transition: -webkit-transform 0.4s ease-in-out;
      transition: transform 0.4s ease-in-out;
      transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
      transform: translateZ(0) scale(0.85);
      -webkit-transform: translateZ(0) scale(0.85);
    }
  }
  i {
    position: absolute;
    left: 15px;
    top: 20px;
    font-size: 20px;
    color: #050706;
  }
} // form-input-wrap
// theme btn
.yellow-btn {
  // --background: #fdbe21;
  // --background-focused: #fdbe21;
  // --background-activated: #fdbe21;
  --background: #fec027;
  --background-focused: #fec027;
  --background-activated: #fec027;
  --color: #fff;
  --color-activated: #fff;
  --color-focused: #fff;
  display: block;
  margin: 20px auto;
  max-width: 240px;
  text-transform: uppercase;
  font-size: 16px;
  --box-shadow: none;
  letter-spacing: 0.5px;
  --padding-top: 3px;
  height: 45px;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
// alert
.alert-head.sc-ion-alert-ios,
.alert-head.sc-ion-alert-md {
  padding: 12px 12px 10px;
  -webkit-padding-start: 12px;
  padding-inline-start: 12px;
  -webkit-padding-end: 12px;
  padding-inline-end: 12px;
  background: #fee098;
  .alert-title {
    font-size: 16px;
    font-weight: 600;
    --ion-text-color: #050706;
    color: #050706;
    margin-top: 0;
    text-align: center;
  }
}
.alert-message {
  padding: 0 !important;
}
.alert-radio-label.sc-ion-alert-ios,
.alert-radio-label.sc-ion-alert-md {
  -webkit-padding-start: 15px;
  padding-inline-start: 15px;
  -webkit-padding-end: 15px;
  padding-inline-end: 15px;
  padding: 10px 18px;
}
.alert-button.sc-ion-alert-ios,
.alert-button.sc-ion-alert-md {
  font-size: 14px;
  color: #676767;
}
.alert-tappable.sc-ion-alert-ios,
.alert-tappable.sc-ion-alert-md {
  height: 38px;
}
[aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios,
[aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  // color: #fdbe21;
  color: #fec027;
}
[aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios,
[aria-checked="true"].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  // border-color: #fdbe21;
  border-color: #fec027;
}
.alert-radio-inner.sc-ion-alert-md {
  // background-color: #fdbe21;
  background-color: #fec027;
  left: 2px;
  top: 2px;
  width: 8px;
  height: 8px;
}
.alert-radio-icon.sc-ion-alert-md {
  left: 9px;
  width: 16px;
  height: 16px;
  border-color: #676767;
}
.alert-button.sc-ion-alert-md {
  padding-top: 6px;
  padding-bottom: 6px;
}
.alert-radio-group.sc-ion-alert-md,
.alert-radio-group.sc-ion-alert-ios {
  padding: 10px 0;
}
// alert css of Search Popup & Take Quiz Popup (in Dashboard Page)
.search-popup-wrap,
.take-quiz-popup-wrap {
  .search-popup-body {
    padding: 20px;
  }
  .search-head {
    padding: 10px;
    background: #fee098;
    text-align: center;
    font-weight: 700;
  }

  .alert-head.sc-ion-alert-ios,
  .alert-head.sc-ion-alert-md {
    padding: 15px 12px 10px;
    background: #fee098;
  }

  .alert-title {
    text-transform: uppercase;
  }
  .alert-message {
    padding: 16px !important;
    text-align: center;
  }
  .book-to-checkout-txt {
    font-size: 15px;
    font-weight: 500;
    color: #030303;
    text-align: center;
    margin: 15px 0 5px;
  }
  .num-of-books-wrap {
    display: flex;
    justify-content: space-evenly;
    margin: 22px auto 10px;
    max-width: 200px;
    align-items: center;

    ion-button {
      font-size: 16px;
      font-weight: 700;
      --background: transparent;
      --background-focused: #666666;
      --background-activated: #666;
      --border-radius: 50%;
      --border-color: #666;
      --border-width: 1px;
      --border-style: solid;
      --color: #646464;
      --color-activated: #fff;
      --color-focused: #fff;
      --box-shadow: none;
      --padding-top: 4px;
      --padding-bottom: 4px;
      --padding-start: 4px;
      --padding-end: 4px;
      width: 36px;
      height: 36px;
    }
  }
  .include-challenge-books {
    display: flex;
    justify-content: flex-start;
    margin: 15px 0;
    padding: 0 3px;
    ion-checkbox {
      margin-right: 10px;
      margin-top: 9px;
      margin-bottom: 8px;
      --border-color: #bbb;
      min-width: 18px;
      // --background-checked: #fdbe21;
      // --border-color-checked: #fdbe21;
      --background-checked: #fec027;
      --border-color-checked: #fec027;
    }
    ion-checkbox.ios {
      min-width: 26px;
    }
    p {
      margin: 8px 0;
      font-size: 14px;
      color: #888;
      font-weight: 400;
      letter-spacing: 0.2px;
      text-align: left;
    }
  }
  .alert-btn {
    margin: 20px auto 25px;
    font-size: 15px;
    border-radius: 20px;
    font-weight: 400 !important;
    height: 36px;
    // background: #fdbe21;
    background: #fec027;
    color: #fff;
    width: 155px;
    max-width: 155px;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    letter-spacing: 0.5px;
    .alert-button-inner.sc-ion-alert-md {
      justify-content: center;
    }
  }
} // search-pop-wrap
// alert css of Reissue Popup & Lost/Damaged Popup (in Dashboard Page)
.reissue-popup-wrap,
.return-popup-wrap,
.lost-damaged-popup-wrap {
  .alert-head.sc-ion-alert-ios,
  .alert-head.sc-ion-alert-md {
    padding: 15px 12px 10px;
    background: #fee098;
  }

  .alert-title {
    text-transform: uppercase;
  }
  .alert-message {
    padding: 16px !important;
    text-align: center;
  }

  .book-details-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    margin-top: 18px;

    .book-details-wrap {
      display: flex;
      width: 100%;
      margin-bottom: 8px;
    }

    .book-img-wrap {
      position: relative;
      width: 80px;
      height: 100px;
      min-width: 80px;
      margin-right: 12px;

      .book-img {
        // width: 80px;
        height: 100%;
        object-fit: cover;
      }
    } // book-img-wrap

    .book-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      h5 {
        font-size: 14px;
        font-weight: 700;
        color: #050706;
        margin: 5px 0 4px;
      }

      p {
        font-size: 13px;
        font-weight: 400;
        color: #676767;
        margin: 2px 0;

        span {
          font-weight: 700;
          color: #050706;
          margin-left: 2px;
        }
      }
      .author-name {
        font-style: italic;
      }

      hr {
        width: 100%;
        background: #e8e8e8;
      }
    } // book-details
  } // book-details-wrapper
  .alert-btn {
    margin: 20px auto 25px;
    font-size: 15px;
    border-radius: 20px;
    font-weight: 400 !important;
    height: 36px;
    // background: #fdbe21;
    background: #fec027;
    color: #fff;
    width: 180px;
    max-width: 180px;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    letter-spacing: 0.5px;
    .alert-button-inner.sc-ion-alert-md {
      justify-content: center;
    }
  }
} // reissue-popup-wrap
// alert css for Lost/Damaged Popup in dashboard Page
.lost-damaged-popup-wrap {
  .book-price {
    font-size: 16px !important;
  }
  .account-details {
    margin: 1.5px 0;
    font-size: 14px;
    font-weight: 500;
    color: #050706;
    letter-spacing: 0.3px;
    text-align: left;
    span {
      font-weight: 700;
      margin-left: 2px;
    }
  }
  .alert-btn {
    margin: 30px auto 25px;
  }
} //lost-damaged-popup
// alert css of Take Quiz in dashboard page
.take-quiz-popup-wrap {
  .alert-message.sc-ion-alert-md,
  .alert-message.sc-ion-alert-ios {
    min-height: 340px;
  }
  .alert-message.sc-ion-alert-md {
    max-height: 70vh;
  }
  .steps-for-quiz {
    font-size: 15px;
    font-weight: 500;
    color: #030303;
    text-align: center;
    margin: 17px 0 20px;
    text-align: left;
  }
  .open-link-txt,
  p {
    font-size: 14px;
    letter-spacing: 0.3px;
    margin: 10px 0 5px 3px;
    font-weight: 400;
    color: #333;
    text-align: left;
    i {
      font-size: 6px;
      margin-right: 4px;
      vertical-align: 3px;
    }
  }
  p {
    margin: 10px 0 13px 3px;
  }
  .info-wrap {
    // border: 1px solid #fdbe21;
    border: 1px solid #fec027;
    border-radius: 8px;
    padding: 10px 10px 9px;
    font-size: 14px;
    font-weight: 600;
    color: #050706;
    margin-bottom: 15px;
    text-align: left;
    a,
    a:hover,
    a:focus,
    a:active {
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
      color: #050706;
      margin: 0;
      letter-spacing: 0.2px;
    }
  }
  .alert-btn {
    margin: 20px auto 25px;
    width: 140px;
    max-width: 140px;
    .alert-button-inner.sc-ion-alert-md {
      justify-content: center;
    }
  }
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 10px !important;
}
.loading-panel {
  font-size: 14px;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #eee;
  .loading-text {
    vertical-align: top;
    padding: 5px;
    display: inline-block;
  }
}

.no-data {
  text-align: center;
  font-size: 14px;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #eee;
}

[_nghost-com-c3]
  .primary[_ngcontent-com-c3]
  .between[_ngcontent-com-c3]
  button.days-btn[_ngcontent-com-c3] {
  // background-color: #fdbe21 !important;
  background-color: #fec027 !important;
}

.mat-calendar-body-selected {
  // background-color: #fdbe21 !important;
  background-color: #fec027 !important;
}

.mat-calendar-cell-semi-selected {
  background-color: #fee098 !important;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
  border-radius: 100% 0 0 100%;
  background-color: #fee098;
}

.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
  border-radius: 0 100% 100% 0;
  background-color: #fee098;
}

.get-started {
  z-index: 999;

  .login-button {
    height: 56px;
    line-height: 56px;
    --border-radius: 6px;
    font-size: 14px;
  }
}

ion-slide {
  position: relative;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }

  .info {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 70px;
    text-align: left;
    z-index: 999;
    max-width: 400px;

    h2 {
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: #4c495b;
      padding: 0;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #777;
      max-width: 300px;
      padding: 0;
      margin: 0 0 50px;
    }
  }
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.book-img--view {
  width: 90%;
  display: block;
  margin: 0 auto;
  height: 240px;
  object-fit: contain;
}

.book-image-popup-wrapper {
  .alert-message.sc-ion-alert-md {
    text-align: center;
  }
}

img {
  font-size: 12px;
}

.alert-wrapper.sc-ion-alert-md,
.alert-wrapper.sc-ion-alert-ios {
  width: 330px;
  --max-width: 330px;

  .alert-radio-label.sc-ion-alert-md,
  .alert-radio-label.sc-ion-alert-ios {
    text-overflow: unset;
    white-space: normal;
    font-size: 15px;
  }
}

// media styles
@media all and (max-height: 675px) {
  .book-guru-logo {
    width: 250px;
    margin: 4% auto 35px;
  }
}

@media all and (min-width: 992px) {
  .form-input-wrap {
    max-width: 400px;
  }

  .remember-password {
    max-width: 380px !important;
  }

  .yellow-btn {
    max-width: 325px;
  }
}

@media all and (min-width: 1200px) {
  .book-guru-logo {
    margin: 15px auto 35px;
  }
}

.sc-ion-back-button-ios-h {
  color: white !important;
}
.text-center,
.take-quiz-popup-wrap .steps-for-quiz.text-center {
  text-align: center;
}
.take-quiz-popup-wrap .info-wrap input.native-input {
  padding: 0;
}
.new-password-popup .alert-message {
  min-height: 150px !important;
}

.progress-tracker-password .alert-message {
  min-height: unset !important;
}

.lost-damaged-popup-wrap .alert-message {
  max-height: unset !important;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 14px;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 7px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.star-rating {
  display: flex;
  flex-direction: row-reverse;
  font-size: 1.5em;
  justify-content: space-around;
  padding: 0 0.2em;
  text-align: center;
  width: 5em;
  margin-top: 8px;
}

.star-rating input {
  display: none;
}

.star-rating label {
  color: #ccc;
  cursor: pointer;
}

.star-rating :checked ~ label {
  color: #f90;
}

.star-rating label:hover,
.star-rating label:hover ~ label {
  color: #fc0;
}

.alertClass {
  .alert-head {
    background: #ffd740;
  }
  .alert-message {
    max-height: 450px;
    width: 350px;
  }
  .alert-button-group {
    display: none;
  }
}

.quiz_score_alert {
  .alert-message {
    text-align: center !important;
  }

  .alert-button {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);
    border-radius: 16px;
    min-width: 54px;
    .alert-button-inner {
      justify-content: center;
    }
  }
  .alert-button-group.sc-ion-alert-md {
    justify-content: center;
  }
}

.spin_modal {
  height: 500px;
  position: relative;
  top: 20vh;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.rupee_icon {
  font-family: "Courier New", Courier, monospace;
  padding-right: 2px;
}

ion-badge {
  margin-right: 8px;
}
