@font-face {
  font-family: 'fontello';
  src: url('fontello/fontello.eot?5672850');
  src: url('fontello/fontello.eot?5672850#iefix') format('embedded-opentype'),
       url('fontello/fontello.woff2?5672850') format('woff2'),
       url('fontello/fontello.woff?5672850') format('woff'),
       url('fontello/fontello.ttf?5672850') format('truetype'),
       url('fontello/fontello.svg?5672850#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?5672850#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-glass:before { content: '\e800'; } /* '' */
.icon-music:before { content: '\e801'; } /* '' */
.icon-search:before { content: '\e802'; } /* '' */
.icon-mail:before { content: '\e803'; } /* '' */
.icon-heart:before { content: '\e804'; } /* '' */
.icon-heart-empty:before { content: '\e805'; } /* '' */
.icon-star:before { content: '\e806'; } /* '' */
.icon-star-empty:before { content: '\e807'; } /* '' */
.icon-star-half:before { content: '\e808'; } /* '' */
.icon-user:before { content: '\e809'; } /* '' */
.icon-users:before { content: '\e80a'; } /* '' */
.icon-video:before { content: '\e80b'; } /* '' */
.icon-videocam:before { content: '\e80c'; } /* '' */
.icon-picture:before { content: '\e80d'; } /* '' */
.icon-camera:before { content: '\e80e'; } /* '' */
.icon-camera-alt:before { content: '\e80f'; } /* '' */
.icon-th-large:before { content: '\e810'; } /* '' */
.icon-th:before { content: '\e811'; } /* '' */
.icon-th-list:before { content: '\e812'; } /* '' */
.icon-ok:before { content: '\e813'; } /* '' */
.icon-ok-circled:before { content: '\e814'; } /* '' */
.icon-ok-circled2:before { content: '\e815'; } /* '' */
.icon-cancel:before { content: '\e816'; } /* '' */
.icon-cancel-circled:before { content: '\e817'; } /* '' */
.icon-cancel-circled2:before { content: '\e818'; } /* '' */
.icon-plus:before { content: '\e819'; } /* '' */
.icon-plus-circled:before { content: '\e81a'; } /* '' */
.icon-minus:before { content: '\e81b'; } /* '' */
.icon-minus-circled:before { content: '\e81c'; } /* '' */
.icon-help-circled:before { content: '\e81d'; } /* '' */
.icon-info-circled:before { content: '\e81e'; } /* '' */
.icon-home:before { content: '\e81f'; } /* '' */
.icon-link:before { content: '\e820'; } /* '' */
.icon-attach:before { content: '\e821'; } /* '' */
.icon-lock:before { content: '\e822'; } /* '' */
.icon-lock-open:before { content: '\e823'; } /* '' */
.icon-pin:before { content: '\e824'; } /* '' */
.icon-eye:before { content: '\e825'; } /* '' */
.icon-eye-off:before { content: '\e826'; } /* '' */
.icon-tag:before { content: '\e827'; } /* '' */
.icon-tags:before { content: '\e828'; } /* '' */
.icon-bookmark:before { content: '\e829'; } /* '' */
.icon-flag:before { content: '\e82a'; } /* '' */
.icon-thumbs-up:before { content: '\e82b'; } /* '' */
.icon-thumbs-down:before { content: '\e82c'; } /* '' */
.icon-download:before { content: '\e82d'; } /* '' */
.icon-upload:before { content: '\e82e'; } /* '' */
.icon-forward:before { content: '\e82f'; } /* '' */
.icon-export:before { content: '\e830'; } /* '' */
.icon-pencil:before { content: '\e831'; } /* '' */
.icon-edit:before { content: '\e832'; } /* '' */
.icon-print:before { content: '\e833'; } /* '' */
.icon-retweet:before { content: '\e834'; } /* '' */
.icon-comment:before { content: '\e835'; } /* '' */
.icon-chat:before { content: '\e836'; } /* '' */
.icon-bell:before { content: '\e837'; } /* '' */
.icon-attention:before { content: '\e838'; } /* '' */
.icon-attention-circled:before { content: '\e839'; } /* '' */
.icon-location:before { content: '\e83a'; } /* '' */
.icon-trash-empty:before { content: '\e83b'; } /* '' */
.icon-doc:before { content: '\e83c'; } /* '' */
.icon-folder:before { content: '\e83d'; } /* '' */
.icon-folder-open:before { content: '\e83e'; } /* '' */
.icon-phone:before { content: '\e83f'; } /* '' */
.icon-cog:before { content: '\e840'; } /* '' */
.icon-cog-alt:before { content: '\e841'; } /* '' */
.icon-wrench:before { content: '\e842'; } /* '' */
.icon-basket:before { content: '\e843'; } /* '' */
.icon-calendar:before { content: '\e844'; } /* '' */
.icon-login:before { content: '\e845'; } /* '' */
.icon-logout:before { content: '\e846'; } /* '' */
.icon-volume-off:before { content: '\e847'; } /* '' */
.icon-volume-down:before { content: '\e848'; } /* '' */
.icon-volume-up:before { content: '\e849'; } /* '' */
.icon-headphones:before { content: '\e84a'; } /* '' */
.icon-clock:before { content: '\e84b'; } /* '' */
.icon-block:before { content: '\e84c'; } /* '' */
.icon-resize-full:before { content: '\e84d'; } /* '' */
.icon-resize-small:before { content: '\e84e'; } /* '' */
.icon-resize-vertical:before { content: '\e84f'; } /* '' */
.icon-resize-horizontal:before { content: '\e850'; } /* '' */
.icon-zoom-in:before { content: '\e851'; } /* '' */
.icon-zoom-out:before { content: '\e852'; } /* '' */
.icon-down-circled2:before { content: '\e853'; } /* '' */
.icon-up-circled2:before { content: '\e854'; } /* '' */
.icon-down-dir:before { content: '\e855'; } /* '' */
.icon-up-dir:before { content: '\e856'; } /* '' */
.icon-left-dir:before { content: '\e857'; } /* '' */
.icon-right-dir:before { content: '\e858'; } /* '' */
.icon-down-open:before { content: '\e859'; } /* '' */
.icon-left-open:before { content: '\e85a'; } /* '' */
.icon-right-open:before { content: '\e85b'; } /* '' */
.icon-up-open:before { content: '\e85c'; } /* '' */
.icon-down-big:before { content: '\e85d'; } /* '' */
.icon-left-big:before { content: '\e85e'; } /* '' */
.icon-right-big:before { content: '\e85f'; } /* '' */
.icon-up-big:before { content: '\e860'; } /* '' */
.icon-right-hand:before { content: '\e861'; } /* '' */
.icon-left-hand:before { content: '\e862'; } /* '' */
.icon-up-hand:before { content: '\e863'; } /* '' */
.icon-down-hand:before { content: '\e864'; } /* '' */
.icon-cw:before { content: '\e865'; } /* '' */
.icon-ccw:before { content: '\e866'; } /* '' */
.icon-arrows-cw:before { content: '\e867'; } /* '' */
.icon-shuffle:before { content: '\e868'; } /* '' */
.icon-play:before { content: '\e869'; } /* '' */
.icon-play-circled2:before { content: '\e86a'; } /* '' */
.icon-stop:before { content: '\e86b'; } /* '' */
.icon-pause:before { content: '\e86c'; } /* '' */
.icon-to-end:before { content: '\e86d'; } /* '' */
.icon-to-end-alt:before { content: '\e86e'; } /* '' */
.icon-to-start:before { content: '\e86f'; } /* '' */
.icon-to-start-alt:before { content: '\e870'; } /* '' */
.icon-fast-fw:before { content: '\e871'; } /* '' */
.icon-fast-bw:before { content: '\e872'; } /* '' */
.icon-eject:before { content: '\e873'; } /* '' */
.icon-target:before { content: '\e874'; } /* '' */
.icon-signal:before { content: '\e875'; } /* '' */
.icon-award:before { content: '\e876'; } /* '' */
.icon-inbox:before { content: '\e877'; } /* '' */
.icon-globe:before { content: '\e878'; } /* '' */
.icon-cloud:before { content: '\e879'; } /* '' */
.icon-flash:before { content: '\e87a'; } /* '' */
.icon-umbrella:before { content: '\e87b'; } /* '' */
.icon-flight:before { content: '\e87c'; } /* '' */
.icon-leaf:before { content: '\e87d'; } /* '' */
.icon-font:before { content: '\e87e'; } /* '' */
.icon-bold:before { content: '\e87f'; } /* '' */
.icon-italic:before { content: '\e880'; } /* '' */
.icon-text-height:before { content: '\e881'; } /* '' */
.icon-text-width:before { content: '\e882'; } /* '' */
.icon-align-left:before { content: '\e883'; } /* '' */
.icon-align-center:before { content: '\e884'; } /* '' */
.icon-align-right:before { content: '\e885'; } /* '' */
.icon-align-justify:before { content: '\e886'; } /* '' */
.icon-list:before { content: '\e887'; } /* '' */
.icon-indent-left:before { content: '\e888'; } /* '' */
.icon-indent-right:before { content: '\e889'; } /* '' */
.icon-scissors:before { content: '\e88a'; } /* '' */
.icon-briefcase:before { content: '\e88b'; } /* '' */
.icon-off:before { content: '\e88c'; } /* '' */
.icon-road:before { content: '\e88d'; } /* '' */
.icon-list-alt:before { content: '\e88e'; } /* '' */
.icon-qrcode:before { content: '\e88f'; } /* '' */
.icon-barcode:before { content: '\e890'; } /* '' */
.icon-book:before { content: '\e891'; } /* '' */
.icon-adjust:before { content: '\e892'; } /* '' */
.icon-tint:before { content: '\e893'; } /* '' */
.icon-check:before { content: '\e894'; } /* '' */
.icon-asterisk:before { content: '\e895'; } /* '' */
.icon-gift:before { content: '\e896'; } /* '' */
.icon-fire:before { content: '\e897'; } /* '' */
.icon-magnet:before { content: '\e898'; } /* '' */
.icon-chart-bar:before { content: '\e899'; } /* '' */
.icon-credit-card:before { content: '\e89a'; } /* '' */
.icon-floppy:before { content: '\e89b'; } /* '' */
.icon-megaphone:before { content: '\e89c'; } /* '' */
.icon-key:before { content: '\e89d'; } /* '' */
.icon-truck:before { content: '\e89e'; } /* '' */
.icon-hammer:before { content: '\e89f'; } /* '' */
.icon-lemon:before { content: '\e8a0'; } /* '' */
.icon-note:before { content: '\e8a1'; } /* '' */
.icon-note-beamed:before { content: '\e8a2'; } /* '' */
.icon-music-1:before { content: '\e8a3'; } /* '' */
.icon-search-1:before { content: '\e8a4'; } /* '' */
.icon-flashlight:before { content: '\e8a5'; } /* '' */
.icon-mail-1:before { content: '\e8a6'; } /* '' */
.icon-heart-1:before { content: '\e8a7'; } /* '' */
.icon-heart-empty-1:before { content: '\e8a8'; } /* '' */
.icon-star-1:before { content: '\e8a9'; } /* '' */
.icon-star-empty-1:before { content: '\e8aa'; } /* '' */
.icon-user-1:before { content: '\e8ab'; } /* '' */
.icon-users-1:before { content: '\e8ac'; } /* '' */
.icon-user-add:before { content: '\e8ad'; } /* '' */
.icon-video-1:before { content: '\e8ae'; } /* '' */
.icon-picture-1:before { content: '\e8af'; } /* '' */
.icon-camera-1:before { content: '\e8b0'; } /* '' */
.icon-layout:before { content: '\e8b1'; } /* '' */
.icon-menu-1:before { content: '\e8b2'; } /* '' */
.icon-check-1:before { content: '\e8b3'; } /* '' */
.icon-cancel-1:before { content: '\e8b4'; } /* '' */
.icon-cancel-circled-1:before { content: '\e8b5'; } /* '' */
.icon-cancel-squared:before { content: '\e8b6'; } /* '' */
.icon-plus-1:before { content: '\e8b7'; } /* '' */
.icon-plus-circled-1:before { content: '\e8b8'; } /* '' */
.icon-plus-squared-1:before { content: '\e8b9'; } /* '' */
.icon-minus-1:before { content: '\e8ba'; } /* '' */
.icon-minus-circled-1:before { content: '\e8bb'; } /* '' */
.icon-minus-squared-1:before { content: '\e8bc'; } /* '' */
.icon-help-1:before { content: '\e8bd'; } /* '' */
.icon-help-circled-1:before { content: '\e8be'; } /* '' */
.icon-info-1:before { content: '\e8bf'; } /* '' */
.icon-info-circled-1:before { content: '\e8c0'; } /* '' */
.icon-back:before { content: '\e8c1'; } /* '' */
.icon-home-1:before { content: '\e8c2'; } /* '' */
.icon-link-1:before { content: '\e8c3'; } /* '' */
.icon-attach-1:before { content: '\e8c4'; } /* '' */
.icon-lock-1:before { content: '\e8c5'; } /* '' */
.icon-lock-open-1:before { content: '\e8c6'; } /* '' */
.icon-eye-1:before { content: '\e8c7'; } /* '' */
.icon-tag-1:before { content: '\e8c8'; } /* '' */
.icon-bookmark-1:before { content: '\e8c9'; } /* '' */
.icon-bookmarks:before { content: '\e8ca'; } /* '' */
.icon-flag-1:before { content: '\e8cb'; } /* '' */
.icon-thumbs-up-1:before { content: '\e8cc'; } /* '' */
.icon-thumbs-down-1:before { content: '\e8cd'; } /* '' */
.icon-download-1:before { content: '\e8ce'; } /* '' */
.icon-upload-1:before { content: '\e8cf'; } /* '' */
.icon-upload-cloud-1:before { content: '\e8d0'; } /* '' */
.icon-reply-1:before { content: '\e8d1'; } /* '' */
.icon-reply-all-1:before { content: '\e8d2'; } /* '' */
.icon-forward-1:before { content: '\e8d3'; } /* '' */
.icon-quote:before { content: '\e8d4'; } /* '' */
.icon-code-1:before { content: '\e8d5'; } /* '' */
.icon-export-1:before { content: '\e8d6'; } /* '' */
.icon-pencil-1:before { content: '\e8d7'; } /* '' */
.icon-feather:before { content: '\e8d8'; } /* '' */
.icon-print-1:before { content: '\e8d9'; } /* '' */
.icon-retweet-1:before { content: '\e8da'; } /* '' */
.icon-keyboard-1:before { content: '\e8db'; } /* '' */
.icon-comment-1:before { content: '\e8dc'; } /* '' */
.icon-chat-1:before { content: '\e8dd'; } /* '' */
.icon-bell-1:before { content: '\e8de'; } /* '' */
.icon-attention-1:before { content: '\e8df'; } /* '' */
.icon-alert:before { content: '\e8e0'; } /* '' */
.icon-vcard:before { content: '\e8e1'; } /* '' */
.icon-address:before { content: '\e8e2'; } /* '' */
.icon-location-1:before { content: '\e8e3'; } /* '' */
.icon-map-1:before { content: '\e8e4'; } /* '' */
.icon-direction-1:before { content: '\e8e5'; } /* '' */
.icon-compass-1:before { content: '\e8e6'; } /* '' */
.icon-cup:before { content: '\e8e7'; } /* '' */
.icon-trash-1:before { content: '\e8e8'; } /* '' */
.icon-doc-1:before { content: '\e8e9'; } /* '' */
.icon-docs-1:before { content: '\e8ea'; } /* '' */
.icon-doc-landscape:before { content: '\e8eb'; } /* '' */
.icon-doc-text-1:before { content: '\e8ec'; } /* '' */
.icon-doc-text-inv-1:before { content: '\e8ed'; } /* '' */
.icon-newspaper-1:before { content: '\e8ee'; } /* '' */
.icon-book-open:before { content: '\e8ef'; } /* '' */
.icon-book-1:before { content: '\e8f0'; } /* '' */
.icon-folder-1:before { content: '\e8f1'; } /* '' */
.icon-archive:before { content: '\e8f2'; } /* '' */
.icon-box-1:before { content: '\e8f3'; } /* '' */
.icon-rss-1:before { content: '\e8f4'; } /* '' */
.icon-phone-1:before { content: '\e8f5'; } /* '' */
.icon-cog-1:before { content: '\e8f6'; } /* '' */
.icon-tools:before { content: '\e8f7'; } /* '' */
.icon-share-1:before { content: '\e8f8'; } /* '' */
.icon-shareable:before { content: '\e8f9'; } /* '' */
.icon-basket-1:before { content: '\e8fa'; } /* '' */
.icon-bag:before { content: '\e8fb'; } /* '' */
.icon-calendar-1:before { content: '\e8fc'; } /* '' */
.icon-login-1:before { content: '\e8fd'; } /* '' */
.icon-logout-1:before { content: '\e8fe'; } /* '' */
.icon-mic-1:before { content: '\e8ff'; } /* '' */
.icon-mute-1:before { content: '\e900'; } /* '' */
.icon-sound:before { content: '\e901'; } /* '' */
.icon-volume:before { content: '\e902'; } /* '' */
.icon-clock-1:before { content: '\e903'; } /* '' */
.icon-hourglass-4:before { content: '\e904'; } /* '' */
.icon-lamp:before { content: '\e905'; } /* '' */
.icon-light-down:before { content: '\e906'; } /* '' */
.icon-light-up:before { content: '\e907'; } /* '' */
.icon-adjust-1:before { content: '\e908'; } /* '' */
.icon-block-1:before { content: '\e909'; } /* '' */
.icon-resize-full-1:before { content: '\e90a'; } /* '' */
.icon-resize-small-1:before { content: '\e90b'; } /* '' */
.icon-popup:before { content: '\e90c'; } /* '' */
.icon-publish:before { content: '\e90d'; } /* '' */
.icon-window:before { content: '\e90e'; } /* '' */
.icon-arrow-combo:before { content: '\e90f'; } /* '' */
.icon-down-circled-1:before { content: '\e910'; } /* '' */
.icon-left-circled-1:before { content: '\e911'; } /* '' */
.icon-right-circled-1:before { content: '\e912'; } /* '' */
.icon-up-circled-1:before { content: '\e913'; } /* '' */
.icon-down-open-1:before { content: '\e914'; } /* '' */
.icon-left-open-1:before { content: '\e915'; } /* '' */
.icon-right-open-1:before { content: '\e916'; } /* '' */
.icon-up-open-1:before { content: '\e917'; } /* '' */
.icon-down-open-mini:before { content: '\e918'; } /* '' */
.icon-left-open-mini:before { content: '\e919'; } /* '' */
.icon-right-open-mini:before { content: '\e91a'; } /* '' */
.icon-up-open-mini:before { content: '\e91b'; } /* '' */
.icon-down-open-big:before { content: '\e91c'; } /* '' */
.icon-left-open-big:before { content: '\e91d'; } /* '' */
.icon-right-open-big:before { content: '\e91e'; } /* '' */
.icon-up-open-big:before { content: '\e91f'; } /* '' */
.icon-down-1:before { content: '\e920'; } /* '' */
.icon-left-1:before { content: '\e921'; } /* '' */
.icon-right-1:before { content: '\e922'; } /* '' */
.icon-up-1:before { content: '\e923'; } /* '' */
.icon-down-dir-1:before { content: '\e924'; } /* '' */
.icon-left-dir-1:before { content: '\e925'; } /* '' */
.icon-right-dir-1:before { content: '\e926'; } /* '' */
.icon-up-dir-1:before { content: '\e927'; } /* '' */
.icon-down-bold:before { content: '\e928'; } /* '' */
.icon-left-bold:before { content: '\e929'; } /* '' */
.icon-right-bold:before { content: '\e92a'; } /* '' */
.icon-up-bold:before { content: '\e92b'; } /* '' */
.icon-down-thin:before { content: '\e92c'; } /* '' */
.icon-left-thin:before { content: '\e92d'; } /* '' */
.icon-right-thin:before { content: '\e92e'; } /* '' */
.icon-up-thin:before { content: '\e92f'; } /* '' */
.icon-ccw-1:before { content: '\e930'; } /* '' */
.icon-cw-1:before { content: '\e931'; } /* '' */
.icon-arrows-ccw:before { content: '\e932'; } /* '' */
.icon-level-down-1:before { content: '\e933'; } /* '' */
.icon-level-up-1:before { content: '\e934'; } /* '' */
.icon-shuffle-1:before { content: '\e935'; } /* '' */
.icon-loop:before { content: '\e936'; } /* '' */
.icon-switch:before { content: '\e937'; } /* '' */
.icon-play-1:before { content: '\e938'; } /* '' */
.icon-stop-1:before { content: '\e939'; } /* '' */
.icon-pause-1:before { content: '\e93a'; } /* '' */
.icon-record:before { content: '\e93b'; } /* '' */
.icon-to-end-1:before { content: '\e93c'; } /* '' */
.icon-to-start-1:before { content: '\e93d'; } /* '' */
.icon-fast-forward:before { content: '\e93e'; } /* '' */
.icon-fast-backward:before { content: '\e93f'; } /* '' */
.icon-progress-0:before { content: '\e940'; } /* '' */
.icon-progress-1:before { content: '\e941'; } /* '' */
.icon-progress-2:before { content: '\e942'; } /* '' */
.icon-progress-3:before { content: '\e943'; } /* '' */
.icon-target-1:before { content: '\e944'; } /* '' */
.icon-palette:before { content: '\e945'; } /* '' */
.icon-list-1:before { content: '\e946'; } /* '' */
.icon-list-add:before { content: '\e947'; } /* '' */
.icon-signal-1:before { content: '\e948'; } /* '' */
.icon-trophy:before { content: '\e949'; } /* '' */
.icon-battery:before { content: '\e94a'; } /* '' */
.icon-back-in-time:before { content: '\e94b'; } /* '' */
.icon-monitor:before { content: '\e94c'; } /* '' */
.icon-mobile-1:before { content: '\e94d'; } /* '' */
.icon-network:before { content: '\e94e'; } /* '' */
.icon-cd:before { content: '\e94f'; } /* '' */
.icon-inbox-1:before { content: '\e950'; } /* '' */
.icon-install:before { content: '\e951'; } /* '' */
.icon-globe-1:before { content: '\e952'; } /* '' */
.icon-cloud-1:before { content: '\e953'; } /* '' */
.icon-cloud-thunder:before { content: '\e954'; } /* '' */
.icon-flash-1:before { content: '\e955'; } /* '' */
.icon-moon-1:before { content: '\e956'; } /* '' */
.icon-flight-1:before { content: '\e957'; } /* '' */
.icon-paper-plane-1:before { content: '\e958'; } /* '' */
.icon-leaf-1:before { content: '\e959'; } /* '' */
.icon-lifebuoy-1:before { content: '\e95a'; } /* '' */
.icon-mouse:before { content: '\e95b'; } /* '' */
.icon-briefcase-1:before { content: '\e95c'; } /* '' */
.icon-suitcase-1:before { content: '\e95d'; } /* '' */
.icon-dot:before { content: '\e95e'; } /* '' */
.icon-dot-2:before { content: '\e95f'; } /* '' */
.icon-dot-3:before { content: '\e960'; } /* '' */
.icon-brush-1:before { content: '\e961'; } /* '' */
.icon-magnet-1:before { content: '\e962'; } /* '' */
.icon-infinity:before { content: '\e963'; } /* '' */
.icon-erase:before { content: '\e964'; } /* '' */
.icon-chart-pie-1:before { content: '\e965'; } /* '' */
.icon-chart-line-1:before { content: '\e966'; } /* '' */
.icon-chart-bar-1:before { content: '\e967'; } /* '' */
.icon-chart-area-1:before { content: '\e968'; } /* '' */
.icon-tape:before { content: '\e969'; } /* '' */
.icon-graduation-cap-1:before { content: '\e96a'; } /* '' */
.icon-language-1:before { content: '\e96b'; } /* '' */
.icon-ticket-1:before { content: '\e96c'; } /* '' */
.icon-water:before { content: '\e96d'; } /* '' */
.icon-droplet:before { content: '\e96e'; } /* '' */
.icon-air:before { content: '\e96f'; } /* '' */
.icon-credit-card-1:before { content: '\e970'; } /* '' */
.icon-floppy-1:before { content: '\e971'; } /* '' */
.icon-clipboard:before { content: '\e972'; } /* '' */
.icon-megaphone-1:before { content: '\e973'; } /* '' */
.icon-database-1:before { content: '\e974'; } /* '' */
.icon-drive:before { content: '\e975'; } /* '' */
.icon-bucket:before { content: '\e976'; } /* '' */
.icon-thermometer-1:before { content: '\e977'; } /* '' */
.icon-key-1:before { content: '\e978'; } /* '' */
.icon-flow-cascade:before { content: '\e979'; } /* '' */
.icon-flow-branch:before { content: '\e97a'; } /* '' */
.icon-flow-tree:before { content: '\e97b'; } /* '' */
.icon-flow-line:before { content: '\e97c'; } /* '' */
.icon-flow-parallel:before { content: '\e97d'; } /* '' */
.icon-rocket-1:before { content: '\e97e'; } /* '' */
.icon-gauge-1:before { content: '\e97f'; } /* '' */
.icon-traffic-cone:before { content: '\e980'; } /* '' */
.icon-cc-1:before { content: '\e981'; } /* '' */
.icon-cc-by:before { content: '\e982'; } /* '' */
.icon-cc-nc:before { content: '\e983'; } /* '' */
.icon-cc-nc-eu:before { content: '\e984'; } /* '' */
.icon-cc-nc-jp:before { content: '\e985'; } /* '' */
.icon-cc-sa:before { content: '\e986'; } /* '' */
.icon-cc-nd:before { content: '\e987'; } /* '' */
.icon-cc-pd:before { content: '\e988'; } /* '' */
.icon-cc-zero:before { content: '\e989'; } /* '' */
.icon-cc-share:before { content: '\e98a'; } /* '' */
.icon-cc-remix:before { content: '\e98b'; } /* '' */
.icon-github-1:before { content: '\e98c'; } /* '' */
.icon-flickr-circled:before { content: '\e98d'; } /* '' */
.icon-facebook-1:before { content: '\e98e'; } /* '' */
.icon-search-2:before { content: '\e98f'; } /* '' */
.icon-mail-2:before { content: '\e990'; } /* '' */
.icon-heart-2:before { content: '\e991'; } /* '' */
.icon-star-2:before { content: '\e992'; } /* '' */
.icon-user-2:before { content: '\e993'; } /* '' */
.icon-user-woman:before { content: '\e994'; } /* '' */
.icon-user-pair:before { content: '\e995'; } /* '' */
.icon-video-alt:before { content: '\e996'; } /* '' */
.icon-videocam-1:before { content: '\e997'; } /* '' */
.icon-videocam-alt:before { content: '\e998'; } /* '' */
.icon-camera-2:before { content: '\e999'; } /* '' */
.icon-th-1:before { content: '\e99a'; } /* '' */
.icon-th-list-1:before { content: '\e99b'; } /* '' */
.icon-ok-1:before { content: '\e99c'; } /* '' */
.icon-cancel-2:before { content: '\e99d'; } /* '' */
.icon-cancel-circle:before { content: '\e99e'; } /* '' */
.icon-plus-2:before { content: '\e99f'; } /* '' */
.icon-home-2:before { content: '\e9a0'; } /* '' */
.icon-lock-2:before { content: '\e9a1'; } /* '' */
.icon-lock-open-2:before { content: '\e9a2'; } /* '' */
.icon-eye-2:before { content: '\e9a3'; } /* '' */
.icon-tag-2:before { content: '\e9a4'; } /* '' */
.icon-thumbs-up-2:before { content: '\e9a5'; } /* '' */
.icon-thumbs-down-2:before { content: '\e9a6'; } /* '' */
.icon-download-2:before { content: '\e9a7'; } /* '' */
.icon-export-2:before { content: '\e9a8'; } /* '' */
.icon-pencil-2:before { content: '\e9a9'; } /* '' */
.icon-pencil-alt:before { content: '\e9aa'; } /* '' */
.icon-edit-1:before { content: '\e9ab'; } /* '' */
.icon-chat-2:before { content: '\e9ac'; } /* '' */
.icon-print-2:before { content: '\e9ad'; } /* '' */
.icon-bell-2:before { content: '\e9ae'; } /* '' */
.icon-attention-2:before { content: '\e9af'; } /* '' */
.icon-info-2:before { content: '\e9b0'; } /* '' */
.icon-question:before { content: '\e9b1'; } /* '' */
.icon-location-2:before { content: '\e9b2'; } /* '' */
.icon-trash-2:before { content: '\e9b3'; } /* '' */
.icon-doc-2:before { content: '\e9b4'; } /* '' */
.icon-article:before { content: '\e9b5'; } /* '' */
.icon-article-alt:before { content: '\e9b6'; } /* '' */
.icon-rss-2:before { content: '\e9b7'; } /* '' */
.icon-wrench-1:before { content: '\e9b8'; } /* '' */
.icon-basket-2:before { content: '\e9b9'; } /* '' */
.icon-basket-alt:before { content: '\e9ba'; } /* '' */
.icon-calendar-2:before { content: '\e9bb'; } /* '' */
.icon-calendar-alt:before { content: '\e9bc'; } /* '' */
.icon-volume-off-1:before { content: '\e9bd'; } /* '' */
.icon-volume-down-1:before { content: '\e9be'; } /* '' */
.icon-volume-up-1:before { content: '\e9bf'; } /* '' */
.icon-bullhorn:before { content: '\e9c0'; } /* '' */
.icon-clock-2:before { content: '\e9c1'; } /* '' */
.icon-clock-alt:before { content: '\e9c2'; } /* '' */
.icon-stop-2:before { content: '\e9c3'; } /* '' */
.icon-resize-full-2:before { content: '\e9c4'; } /* '' */
.icon-resize-small-2:before { content: '\e9c5'; } /* '' */
.icon-zoom-in-1:before { content: '\e9c6'; } /* '' */
.icon-zoom-out-1:before { content: '\e9c7'; } /* '' */
.icon-popup-1:before { content: '\e9c8'; } /* '' */
.icon-down-dir-2:before { content: '\e9c9'; } /* '' */
.icon-left-dir-2:before { content: '\e9ca'; } /* '' */
.icon-right-dir-2:before { content: '\e9cb'; } /* '' */
.icon-up-dir-2:before { content: '\e9cc'; } /* '' */
.icon-down-2:before { content: '\e9cd'; } /* '' */
.icon-up-2:before { content: '\e9ce'; } /* '' */
.icon-cw-2:before { content: '\e9cf'; } /* '' */
.icon-signal-2:before { content: '\e9d0'; } /* '' */
.icon-award-1:before { content: '\e9d1'; } /* '' */
.icon-mobile-2:before { content: '\e9d2'; } /* '' */
.icon-mobile-alt:before { content: '\e9d3'; } /* '' */
.icon-tablet-1:before { content: '\e9d4'; } /* '' */
.icon-ipod:before { content: '\e9d5'; } /* '' */
.icon-cd-1:before { content: '\e9d6'; } /* '' */
.icon-grid:before { content: '\e9d7'; } /* '' */
.icon-book-2:before { content: '\e9d8'; } /* '' */
.icon-easel:before { content: '\e9d9'; } /* '' */
.icon-globe-2:before { content: '\e9da'; } /* '' */
.icon-chart:before { content: '\e9db'; } /* '' */
.icon-chart-bar-2:before { content: '\e9dc'; } /* '' */
.icon-chart-pie-2:before { content: '\e9dd'; } /* '' */
.icon-dollar-1:before { content: '\e9de'; } /* '' */
.icon-at-1:before { content: '\e9df'; } /* '' */
.icon-colon:before { content: '\e9e0'; } /* '' */
.icon-semicolon:before { content: '\e9e1'; } /* '' */
.icon-squares:before { content: '\e9e2'; } /* '' */
.icon-money-1:before { content: '\e9e3'; } /* '' */
.icon-facebook-2:before { content: '\e9e4'; } /* '' */
.icon-facebook-rect:before { content: '\e9e5'; } /* '' */
.icon-twitter-bird:before { content: '\e9e6'; } /* '' */
.icon-youtube-1:before { content: '\e9e7'; } /* '' */
.icon-move:before { content: '\f047'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-phone-squared:before { content: '\f098'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-github-circled:before { content: '\f09b'; } /* '' */
.icon-rss:before { content: '\f09e'; } /* '' */
.icon-hdd:before { content: '\f0a0'; } /* '' */
.icon-certificate:before { content: '\f0a3'; } /* '' */
.icon-left-circled:before { content: '\f0a8'; } /* '' */
.icon-right-circled:before { content: '\f0a9'; } /* '' */
.icon-up-circled:before { content: '\f0aa'; } /* '' */
.icon-down-circled:before { content: '\f0ab'; } /* '' */
.icon-tasks:before { content: '\f0ae'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-resize-full-alt:before { content: '\f0b2'; } /* '' */
.icon-beaker:before { content: '\f0c3'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-blank:before { content: '\f0c8'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-list-bullet:before { content: '\f0ca'; } /* '' */
.icon-list-numbered:before { content: '\f0cb'; } /* '' */
.icon-strike:before { content: '\f0cc'; } /* '' */
.icon-underline:before { content: '\f0cd'; } /* '' */
.icon-table:before { content: '\f0ce'; } /* '' */
.icon-magic:before { content: '\f0d0'; } /* '' */
.icon-pinterest-circled:before { content: '\f0d2'; } /* '' */
.icon-pinterest-squared:before { content: '\f0d3'; } /* '' */
.icon-gplus-squared:before { content: '\f0d4'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-money:before { content: '\f0d6'; } /* '' */
.icon-columns:before { content: '\f0db'; } /* '' */
.icon-sort:before { content: '\f0dc'; } /* '' */
.icon-sort-down:before { content: '\f0dd'; } /* '' */
.icon-sort-up:before { content: '\f0de'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-gauge:before { content: '\f0e4'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-sitemap:before { content: '\f0e8'; } /* '' */
.icon-paste:before { content: '\f0ea'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-exchange:before { content: '\f0ec'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-user-md:before { content: '\f0f0'; } /* '' */
.icon-stethoscope:before { content: '\f0f1'; } /* '' */
.icon-suitcase:before { content: '\f0f2'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-coffee:before { content: '\f0f4'; } /* '' */
.icon-food:before { content: '\f0f5'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-hospital:before { content: '\f0f8'; } /* '' */
.icon-ambulance:before { content: '\f0f9'; } /* '' */
.icon-medkit:before { content: '\f0fa'; } /* '' */
.icon-fighter-jet:before { content: '\f0fb'; } /* '' */
.icon-beer:before { content: '\f0fc'; } /* '' */
.icon-h-sigh:before { content: '\f0fd'; } /* '' */
.icon-plus-squared:before { content: '\f0fe'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-desktop:before { content: '\f108'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-spinner:before { content: '\f110'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-reply:before { content: '\f112'; } /* '' */
.icon-github:before { content: '\f113'; } /* '' */
.icon-folder-empty:before { content: '\f114'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-gamepad:before { content: '\f11b'; } /* '' */
.icon-keyboard:before { content: '\f11c'; } /* '' */
.icon-flag-empty:before { content: '\f11d'; } /* '' */
.icon-flag-checkered:before { content: '\f11e'; } /* '' */
.icon-terminal:before { content: '\f120'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-reply-all:before { content: '\f122'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-crop:before { content: '\f125'; } /* '' */
.icon-fork:before { content: '\f126'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-help:before { content: '\f128'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-superscript:before { content: '\f12b'; } /* '' */
.icon-subscript:before { content: '\f12c'; } /* '' */
.icon-eraser:before { content: '\f12d'; } /* '' */
.icon-puzzle:before { content: '\f12e'; } /* '' */
.icon-mic:before { content: '\f130'; } /* '' */
.icon-mute:before { content: '\f131'; } /* '' */
.icon-shield:before { content: '\f132'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-extinguisher:before { content: '\f134'; } /* '' */
.icon-rocket:before { content: '\f135'; } /* '' */
.icon-maxcdn:before { content: '\f136'; } /* '' */
.icon-angle-circled-left:before { content: '\f137'; } /* '' */
.icon-angle-circled-right:before { content: '\f138'; } /* '' */
.icon-angle-circled-up:before { content: '\f139'; } /* '' */
.icon-angle-circled-down:before { content: '\f13a'; } /* '' */
.icon-html5:before { content: '\f13b'; } /* '' */
.icon-css3:before { content: '\f13c'; } /* '' */
.icon-anchor:before { content: '\f13d'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-bullseye:before { content: '\f140'; } /* '' */
.icon-ellipsis:before { content: '\f141'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-rss-squared:before { content: '\f143'; } /* '' */
.icon-play-circled:before { content: '\f144'; } /* '' */
.icon-ticket:before { content: '\f145'; } /* '' */
.icon-minus-squared:before { content: '\f146'; } /* '' */
.icon-minus-squared-alt:before { content: '\f147'; } /* '' */
.icon-level-up:before { content: '\f148'; } /* '' */
.icon-level-down:before { content: '\f149'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-pencil-squared:before { content: '\f14b'; } /* '' */
.icon-link-ext-alt:before { content: '\f14c'; } /* '' */
.icon-export-alt:before { content: '\f14d'; } /* '' */
.icon-compass:before { content: '\f14e'; } /* '' */
.icon-expand:before { content: '\f150'; } /* '' */
.icon-collapse:before { content: '\f151'; } /* '' */
.icon-expand-right:before { content: '\f152'; } /* '' */
.icon-euro:before { content: '\f153'; } /* '' */
.icon-pound:before { content: '\f154'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
.icon-rupee:before { content: '\f156'; } /* '' */
.icon-yen:before { content: '\f157'; } /* '' */
.icon-rouble:before { content: '\f158'; } /* '' */
.icon-won:before { content: '\f159'; } /* '' */
.icon-bitcoin:before { content: '\f15a'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-sort-name-up:before { content: '\f15d'; } /* '' */
.icon-sort-name-down:before { content: '\f15e'; } /* '' */
.icon-sort-alt-up:before { content: '\f160'; } /* '' */
.icon-sort-alt-down:before { content: '\f161'; } /* '' */
.icon-sort-number-up:before { content: '\f162'; } /* '' */
.icon-sort-number-down:before { content: '\f163'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-youtube-squared:before { content: '\f166'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-xing:before { content: '\f168'; } /* '' */
.icon-xing-squared:before { content: '\f169'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-dropbox:before { content: '\f16b'; } /* '' */
.icon-stackoverflow:before { content: '\f16c'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-flickr:before { content: '\f16e'; } /* '' */
.icon-adn:before { content: '\f170'; } /* '' */
.icon-bitbucket:before { content: '\f171'; } /* '' */
.icon-bitbucket-squared:before { content: '\f172'; } /* '' */
.icon-tumblr:before { content: '\f173'; } /* '' */
.icon-tumblr-squared:before { content: '\f174'; } /* '' */
.icon-down:before { content: '\f175'; } /* '' */
.icon-up:before { content: '\f176'; } /* '' */
.icon-left:before { content: '\f177'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-apple:before { content: '\f179'; } /* '' */
.icon-windows:before { content: '\f17a'; } /* '' */
.icon-android:before { content: '\f17b'; } /* '' */
.icon-linux:before { content: '\f17c'; } /* '' */
.icon-dribbble:before { content: '\f17d'; } /* '' */
.icon-skype:before { content: '\f17e'; } /* '' */
.icon-foursquare:before { content: '\f180'; } /* '' */
.icon-trello:before { content: '\f181'; } /* '' */
.icon-female:before { content: '\f182'; } /* '' */
.icon-male:before { content: '\f183'; } /* '' */
.icon-gittip:before { content: '\f184'; } /* '' */
.icon-sun:before { content: '\f185'; } /* '' */
.icon-moon:before { content: '\f186'; } /* '' */
.icon-box:before { content: '\f187'; } /* '' */
.icon-bug:before { content: '\f188'; } /* '' */
.icon-vkontakte:before { content: '\f189'; } /* '' */
.icon-weibo:before { content: '\f18a'; } /* '' */
.icon-renren:before { content: '\f18b'; } /* '' */
.icon-pagelines:before { content: '\f18c'; } /* '' */
.icon-stackexchange:before { content: '\f18d'; } /* '' */
.icon-right-circled2:before { content: '\f18e'; } /* '' */
.icon-left-circled2:before { content: '\f190'; } /* '' */
.icon-collapse-left:before { content: '\f191'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-wheelchair:before { content: '\f193'; } /* '' */
.icon-vimeo-squared:before { content: '\f194'; } /* '' */
.icon-try:before { content: '\f195'; } /* '' */
.icon-plus-squared-alt:before { content: '\f196'; } /* '' */
.icon-space-shuttle:before { content: '\f197'; } /* '' */
.icon-slack:before { content: '\f198'; } /* '' */
.icon-mail-squared:before { content: '\f199'; } /* '' */
.icon-wordpress:before { content: '\f19a'; } /* '' */
.icon-openid:before { content: '\f19b'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-graduation-cap:before { content: '\f19d'; } /* '' */
.icon-yahoo:before { content: '\f19e'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-reddit:before { content: '\f1a1'; } /* '' */
.icon-reddit-squared:before { content: '\f1a2'; } /* '' */
.icon-stumbleupon-circled:before { content: '\f1a3'; } /* '' */
.icon-stumbleupon:before { content: '\f1a4'; } /* '' */
.icon-delicious:before { content: '\f1a5'; } /* '' */
.icon-digg:before { content: '\f1a6'; } /* '' */
.icon-pied-piper-squared:before { content: '\f1a7'; } /* '' */
.icon-pied-piper-alt:before { content: '\f1a8'; } /* '' */
.icon-drupal:before { content: '\f1a9'; } /* '' */
.icon-joomla:before { content: '\f1aa'; } /* '' */
.icon-language:before { content: '\f1ab'; } /* '' */
.icon-fax:before { content: '\f1ac'; } /* '' */
.icon-building-filled:before { content: '\f1ad'; } /* '' */
.icon-child:before { content: '\f1ae'; } /* '' */
.icon-paw:before { content: '\f1b0'; } /* '' */
.icon-spoon:before { content: '\f1b1'; } /* '' */
.icon-cube:before { content: '\f1b2'; } /* '' */
.icon-cubes:before { content: '\f1b3'; } /* '' */
.icon-behance:before { content: '\f1b4'; } /* '' */
.icon-behance-squared:before { content: '\f1b5'; } /* '' */
.icon-steam:before { content: '\f1b6'; } /* '' */
.icon-steam-squared:before { content: '\f1b7'; } /* '' */
.icon-recycle:before { content: '\f1b8'; } /* '' */
.icon-cab:before { content: '\f1b9'; } /* '' */
.icon-taxi:before { content: '\f1ba'; } /* '' */
.icon-tree:before { content: '\f1bb'; } /* '' */
.icon-spotify:before { content: '\f1bc'; } /* '' */
.icon-deviantart:before { content: '\f1bd'; } /* '' */
.icon-soundcloud:before { content: '\f1be'; } /* '' */
.icon-database:before { content: '\f1c0'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-file-archive:before { content: '\f1c6'; } /* '' */
.icon-file-audio:before { content: '\f1c7'; } /* '' */
.icon-file-video:before { content: '\f1c8'; } /* '' */
.icon-file-code:before { content: '\f1c9'; } /* '' */
.icon-vine:before { content: '\f1ca'; } /* '' */
.icon-codeopen:before { content: '\f1cb'; } /* '' */
.icon-jsfiddle:before { content: '\f1cc'; } /* '' */
.icon-lifebuoy:before { content: '\f1cd'; } /* '' */
.icon-circle-notch:before { content: '\f1ce'; } /* '' */
.icon-rebel:before { content: '\f1d0'; } /* '' */
.icon-empire:before { content: '\f1d1'; } /* '' */
.icon-git-squared:before { content: '\f1d2'; } /* '' */
.icon-git:before { content: '\f1d3'; } /* '' */
.icon-hacker-news:before { content: '\f1d4'; } /* '' */
.icon-tencent-weibo:before { content: '\f1d5'; } /* '' */
.icon-qq:before { content: '\f1d6'; } /* '' */
.icon-wechat:before { content: '\f1d7'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-circle-thin:before { content: '\f1db'; } /* '' */
.icon-header:before { content: '\f1dc'; } /* '' */
.icon-paragraph:before { content: '\f1dd'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-share:before { content: '\f1e0'; } /* '' */
.icon-share-squared:before { content: '\f1e1'; } /* '' */
.icon-bomb:before { content: '\f1e2'; } /* '' */
.icon-soccer-ball:before { content: '\f1e3'; } /* '' */
.icon-tty:before { content: '\f1e4'; } /* '' */
.icon-binoculars:before { content: '\f1e5'; } /* '' */
.icon-plug:before { content: '\f1e6'; } /* '' */
.icon-slideshare:before { content: '\f1e7'; } /* '' */
.icon-twitch:before { content: '\f1e8'; } /* '' */
.icon-yelp:before { content: '\f1e9'; } /* '' */
.icon-newspaper:before { content: '\f1ea'; } /* '' */
.icon-wifi:before { content: '\f1eb'; } /* '' */
.icon-calc:before { content: '\f1ec'; } /* '' */
.icon-paypal:before { content: '\f1ed'; } /* '' */
.icon-gwallet:before { content: '\f1ee'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-discover:before { content: '\f1f2'; } /* '' */
.icon-cc-amex:before { content: '\f1f3'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-cc-stripe:before { content: '\f1f5'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-bell-off-empty:before { content: '\f1f7'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-copyright:before { content: '\f1f9'; } /* '' */
.icon-at:before { content: '\f1fa'; } /* '' */
.icon-eyedropper:before { content: '\f1fb'; } /* '' */
.icon-brush:before { content: '\f1fc'; } /* '' */
.icon-birthday:before { content: '\f1fd'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-pie:before { content: '\f200'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-lastfm:before { content: '\f202'; } /* '' */
.icon-lastfm-squared:before { content: '\f203'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-bicycle:before { content: '\f206'; } /* '' */
.icon-bus:before { content: '\f207'; } /* '' */
.icon-ioxhost:before { content: '\f208'; } /* '' */
.icon-angellist:before { content: '\f209'; } /* '' */
.icon-cc:before { content: '\f20a'; } /* '' */
.icon-shekel:before { content: '\f20b'; } /* '' */
.icon-meanpath:before { content: '\f20c'; } /* '' */
.icon-buysellads:before { content: '\f20d'; } /* '' */
.icon-connectdevelop:before { content: '\f20e'; } /* '' */
.icon-dashcube:before { content: '\f210'; } /* '' */
.icon-forumbee:before { content: '\f211'; } /* '' */
.icon-leanpub:before { content: '\f212'; } /* '' */
.icon-sellsy:before { content: '\f213'; } /* '' */
.icon-shirtsinbulk:before { content: '\f214'; } /* '' */
.icon-simplybuilt:before { content: '\f215'; } /* '' */
.icon-skyatlas:before { content: '\f216'; } /* '' */
.icon-cart-plus:before { content: '\f217'; } /* '' */
.icon-cart-arrow-down:before { content: '\f218'; } /* '' */
.icon-diamond:before { content: '\f219'; } /* '' */
.icon-ship:before { content: '\f21a'; } /* '' */
.icon-user-secret:before { content: '\f21b'; } /* '' */
.icon-motorcycle:before { content: '\f21c'; } /* '' */
.icon-street-view:before { content: '\f21d'; } /* '' */
.icon-heartbeat:before { content: '\f21e'; } /* '' */
.icon-venus:before { content: '\f221'; } /* '' */
.icon-mars:before { content: '\f222'; } /* '' */
.icon-mercury:before { content: '\f223'; } /* '' */
.icon-transgender:before { content: '\f224'; } /* '' */
.icon-transgender-alt:before { content: '\f225'; } /* '' */
.icon-venus-double:before { content: '\f226'; } /* '' */
.icon-mars-double:before { content: '\f227'; } /* '' */
.icon-venus-mars:before { content: '\f228'; } /* '' */
.icon-mars-stroke:before { content: '\f229'; } /* '' */
.icon-mars-stroke-v:before { content: '\f22a'; } /* '' */
.icon-mars-stroke-h:before { content: '\f22b'; } /* '' */
.icon-neuter:before { content: '\f22c'; } /* '' */
.icon-genderless:before { content: '\f22d'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-server:before { content: '\f233'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-bed:before { content: '\f236'; } /* '' */
.icon-viacoin:before { content: '\f237'; } /* '' */
.icon-train:before { content: '\f238'; } /* '' */
.icon-subway:before { content: '\f239'; } /* '' */
.icon-medium:before { content: '\f23a'; } /* '' */
.icon-y-combinator:before { content: '\f23b'; } /* '' */
.icon-optin-monster:before { content: '\f23c'; } /* '' */
.icon-opencart:before { content: '\f23d'; } /* '' */
.icon-expeditedssl:before { content: '\f23e'; } /* '' */
.icon-battery-4:before { content: '\f240'; } /* '' */
.icon-battery-3:before { content: '\f241'; } /* '' */
.icon-battery-2:before { content: '\f242'; } /* '' */
.icon-battery-1:before { content: '\f243'; } /* '' */
.icon-battery-0:before { content: '\f244'; } /* '' */
.icon-mouse-pointer:before { content: '\f245'; } /* '' */
.icon-i-cursor:before { content: '\f246'; } /* '' */
.icon-object-group:before { content: '\f247'; } /* '' */
.icon-object-ungroup:before { content: '\f248'; } /* '' */
.icon-sticky-note:before { content: '\f249'; } /* '' */
.icon-sticky-note-o:before { content: '\f24a'; } /* '' */
.icon-cc-jcb:before { content: '\f24b'; } /* '' */
.icon-cc-diners-club:before { content: '\f24c'; } /* '' */
.icon-clone:before { content: '\f24d'; } /* '' */
.icon-balance-scale:before { content: '\f24e'; } /* '' */
.icon-hourglass-o:before { content: '\f250'; } /* '' */
.icon-hourglass-1:before { content: '\f251'; } /* '' */
.icon-hourglass-2:before { content: '\f252'; } /* '' */
.icon-hourglass-3:before { content: '\f253'; } /* '' */
.icon-hourglass:before { content: '\f254'; } /* '' */
.icon-hand-grab-o:before { content: '\f255'; } /* '' */
.icon-hand-paper-o:before { content: '\f256'; } /* '' */
.icon-hand-scissors-o:before { content: '\f257'; } /* '' */
.icon-hand-lizard-o:before { content: '\f258'; } /* '' */
.icon-hand-spock-o:before { content: '\f259'; } /* '' */
.icon-hand-pointer-o:before { content: '\f25a'; } /* '' */
.icon-hand-peace-o:before { content: '\f25b'; } /* '' */
.icon-trademark:before { content: '\f25c'; } /* '' */
.icon-registered:before { content: '\f25d'; } /* '' */
.icon-creative-commons:before { content: '\f25e'; } /* '' */
.icon-gg:before { content: '\f260'; } /* '' */
.icon-gg-circle:before { content: '\f261'; } /* '' */
.icon-tripadvisor:before { content: '\f262'; } /* '' */
.icon-odnoklassniki:before { content: '\f263'; } /* '' */
.icon-odnoklassniki-square:before { content: '\f264'; } /* '' */
.icon-get-pocket:before { content: '\f265'; } /* '' */
.icon-wikipedia-w:before { content: '\f266'; } /* '' */
.icon-safari:before { content: '\f267'; } /* '' */
.icon-chrome:before { content: '\f268'; } /* '' */
.icon-firefox:before { content: '\f269'; } /* '' */
.icon-opera:before { content: '\f26a'; } /* '' */
.icon-internet-explorer:before { content: '\f26b'; } /* '' */
.icon-television:before { content: '\f26c'; } /* '' */
.icon-contao:before { content: '\f26d'; } /* '' */
.icon-500px:before { content: '\f26e'; } /* '' */
.icon-amazon:before { content: '\f270'; } /* '' */
.icon-calendar-plus-o:before { content: '\f271'; } /* '' */
.icon-calendar-minus-o:before { content: '\f272'; } /* '' */
.icon-calendar-times-o:before { content: '\f273'; } /* '' */
.icon-calendar-check-o:before { content: '\f274'; } /* '' */
.icon-industry:before { content: '\f275'; } /* '' */
.icon-map-pin:before { content: '\f276'; } /* '' */
.icon-map-signs:before { content: '\f277'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-map:before { content: '\f279'; } /* '' */
.icon-commenting:before { content: '\f27a'; } /* '' */
.icon-commenting-o:before { content: '\f27b'; } /* '' */
.icon-houzz:before { content: '\f27c'; } /* '' */
.icon-vimeo:before { content: '\f27d'; } /* '' */
.icon-black-tie:before { content: '\f27e'; } /* '' */
.icon-fonticons:before { content: '\f280'; } /* '' */
.icon-reddit-alien:before { content: '\f281'; } /* '' */
.icon-edge:before { content: '\f282'; } /* '' */
.icon-credit-card-alt:before { content: '\f283'; } /* '' */
.icon-codiepie:before { content: '\f284'; } /* '' */
.icon-modx:before { content: '\f285'; } /* '' */
.icon-fort-awesome:before { content: '\f286'; } /* '' */
.icon-usb:before { content: '\f287'; } /* '' */
.icon-product-hunt:before { content: '\f288'; } /* '' */
.icon-mixcloud:before { content: '\f289'; } /* '' */
.icon-scribd:before { content: '\f28a'; } /* '' */
.icon-pause-circle:before { content: '\f28b'; } /* '' */
.icon-pause-circle-o:before { content: '\f28c'; } /* '' */
.icon-stop-circle:before { content: '\f28d'; } /* '' */
.icon-stop-circle-o:before { content: '\f28e'; } /* '' */
.icon-shopping-bag:before { content: '\f290'; } /* '' */
.icon-shopping-basket:before { content: '\f291'; } /* '' */
.icon-hashtag:before { content: '\f292'; } /* '' */
.icon-bluetooth:before { content: '\f293'; } /* '' */
.icon-bluetooth-b:before { content: '\f294'; } /* '' */
.icon-percent:before { content: '\f295'; } /* '' */
.icon-gitlab:before { content: '\f296'; } /* '' */
.icon-wpbeginner:before { content: '\f297'; } /* '' */
.icon-wpforms:before { content: '\f298'; } /* '' */
.icon-envira:before { content: '\f299'; } /* '' */
.icon-universal-access:before { content: '\f29a'; } /* '' */
.icon-wheelchair-alt:before { content: '\f29b'; } /* '' */
.icon-question-circle-o:before { content: '\f29c'; } /* '' */
.icon-blind:before { content: '\f29d'; } /* '' */
.icon-audio-description:before { content: '\f29e'; } /* '' */
.icon-volume-control-phone:before { content: '\f2a0'; } /* '' */
.icon-braille:before { content: '\f2a1'; } /* '' */
.icon-assistive-listening-systems:before { content: '\f2a2'; } /* '' */
.icon-american-sign-language-interpreting:before { content: '\f2a3'; } /* '' */
.icon-asl-interpreting:before { content: '\f2a4'; } /* '' */
.icon-glide:before { content: '\f2a5'; } /* '' */
.icon-glide-g:before { content: '\f2a6'; } /* '' */
.icon-sign-language:before { content: '\f2a7'; } /* '' */
.icon-low-vision:before { content: '\f2a8'; } /* '' */
.icon-viadeo:before { content: '\f2a9'; } /* '' */
.icon-viadeo-square:before { content: '\f2aa'; } /* '' */
.icon-snapchat:before { content: '\f2ab'; } /* '' */
.icon-snapchat-ghost:before { content: '\f2ac'; } /* '' */
.icon-snapchat-square:before { content: '\f2ad'; } /* '' */
.icon-pied-piper:before { content: '\f2ae'; } /* '' */
.icon-first-order:before { content: '\f2b0'; } /* '' */
.icon-yoast:before { content: '\f2b1'; } /* '' */
.icon-themeisle:before { content: '\f2b2'; } /* '' */
.icon-google-plus-circle:before { content: '\f2b3'; } /* '' */
.icon-font-awesome:before { content: '\f2b4'; } /* '' */
.icon-handshake-o:before { content: '\f2b5'; } /* '' */
.icon-envelope-open:before { content: '\f2b6'; } /* '' */
.icon-envelope-open-o:before { content: '\f2b7'; } /* '' */
.icon-linode:before { content: '\f2b8'; } /* '' */
.icon-address-book:before { content: '\f2b9'; } /* '' */
.icon-address-book-o:before { content: '\f2ba'; } /* '' */
.icon-address-card:before { content: '\f2bb'; } /* '' */
.icon-address-card-o:before { content: '\f2bc'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */
.icon-id-badge:before { content: '\f2c1'; } /* '' */
.icon-id-card:before { content: '\f2c2'; } /* '' */
.icon-id-card-o:before { content: '\f2c3'; } /* '' */
.icon-quora:before { content: '\f2c4'; } /* '' */
.icon-free-code-camp:before { content: '\f2c5'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
.icon-thermometer:before { content: '\f2c7'; } /* '' */
.icon-thermometer-3:before { content: '\f2c8'; } /* '' */
.icon-thermometer-2:before { content: '\f2c9'; } /* '' */
.icon-thermometer-quarter:before { content: '\f2ca'; } /* '' */
.icon-thermometer-0:before { content: '\f2cb'; } /* '' */
.icon-shower:before { content: '\f2cc'; } /* '' */
.icon-bath:before { content: '\f2cd'; } /* '' */
.icon-podcast:before { content: '\f2ce'; } /* '' */
.icon-window-maximize:before { content: '\f2d0'; } /* '' */
.icon-window-minimize:before { content: '\f2d1'; } /* '' */
.icon-window-restore:before { content: '\f2d2'; } /* '' */
.icon-window-close:before { content: '\f2d3'; } /* '' */
.icon-window-close-o:before { content: '\f2d4'; } /* '' */
.icon-bandcamp:before { content: '\f2d5'; } /* '' */
.icon-grav:before { content: '\f2d6'; } /* '' */
.icon-etsy:before { content: '\f2d7'; } /* '' */
.icon-imdb:before { content: '\f2d8'; } /* '' */
.icon-ravelry:before { content: '\f2d9'; } /* '' */
.icon-eercast:before { content: '\f2da'; } /* '' */
.icon-microchip:before { content: '\f2db'; } /* '' */
.icon-snowflake-o:before { content: '\f2dc'; } /* '' */
.icon-superpowers:before { content: '\f2dd'; } /* '' */
.icon-wpexplorer:before { content: '\f2de'; } /* '' */
.icon-meetup:before { content: '\f2e0'; } /* '' */
.icon-github-squared:before { content: '\f300'; } /* '' */
.icon-github-circled-1:before { content: '\f301'; } /* '' */
.icon-twitter-2:before { content: '\f302'; } /* '' */
.icon-flickr-1:before { content: '\f303'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-vimeo-1:before { content: '\f306'; } /* '' */
.icon-vimeo-circled:before { content: '\f307'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-twitter-1:before { content: '\f309'; } /* '' */
.icon-twitter-circled:before { content: '\f30a'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */
.icon-facebook-circled:before { content: '\f30d'; } /* '' */
.icon-facebook-squared-1:before { content: '\f30e'; } /* '' */
.icon-gplus-1:before { content: '\f30f'; } /* '' */
.icon-gplus-circled:before { content: '\f310'; } /* '' */
.icon-pinterest-1:before { content: '\f312'; } /* '' */
.icon-pinterest-circled-1:before { content: '\f313'; } /* '' */
.icon-twitter-rect:before { content: '\f314'; } /* '' */
.icon-tumblr-1:before { content: '\f315'; } /* '' */
.icon-tumblr-circled:before { content: '\f316'; } /* '' */
.icon-linkedin-1:before { content: '\f318'; } /* '' */
.icon-linkedin-circled:before { content: '\f319'; } /* '' */
.icon-dribbble-1:before { content: '\f31b'; } /* '' */
.icon-dribbble-circled:before { content: '\f31c'; } /* '' */
.icon-stumbleupon-1:before { content: '\f31e'; } /* '' */
.icon-stumbleupon-circled-1:before { content: '\f31f'; } /* '' */
.icon-lastfm-1:before { content: '\f321'; } /* '' */
.icon-lastfm-circled:before { content: '\f322'; } /* '' */
.icon-rdio:before { content: '\f324'; } /* '' */
.icon-rdio-circled:before { content: '\f325'; } /* '' */
.icon-spotify-1:before { content: '\f327'; } /* '' */
.icon-spotify-circled:before { content: '\f328'; } /* '' */
.icon-qq-1:before { content: '\f32a'; } /* '' */
.icon-instagram-1:before { content: '\f32d'; } /* '' */
.icon-dropbox-1:before { content: '\f330'; } /* '' */
.icon-evernote:before { content: '\f333'; } /* '' */
.icon-flattr:before { content: '\f336'; } /* '' */
.icon-skype-1:before { content: '\f339'; } /* '' */
.icon-skype-circled:before { content: '\f33a'; } /* '' */
.icon-renren-1:before { content: '\f33c'; } /* '' */
.icon-sina-weibo:before { content: '\f33f'; } /* '' */
.icon-paypal-1:before { content: '\f342'; } /* '' */
.icon-picasa:before { content: '\f345'; } /* '' */
.icon-soundcloud-1:before { content: '\f348'; } /* '' */
.icon-mixi:before { content: '\f34b'; } /* '' */
.icon-behance-1:before { content: '\f34e'; } /* '' */
.icon-google-circles:before { content: '\f351'; } /* '' */
.icon-vkontakte-1:before { content: '\f354'; } /* '' */
.icon-smashing:before { content: '\f357'; } /* '' */
.icon-db-shape:before { content: '\f600'; } /* '' */
.icon-sweden:before { content: '\f601'; } /* '' */
.icon-logo-db:before { content: '\f603'; } /* '' */


/*css for icons sizes and effects*/
.icon-tiny {
  font-size: 12px;
}
.icon-xxsmall {
  font-size: 16px;
}
.icon-xsmall {
  font-size: 20px;
}
.icon-small {
  font-size: 24px;
}
.icon-medium {
  font-size: 32px;
}
.icon-large {
  font-size: 36px;
}
.icon-xlarge {
  font-size: 48px;
}
.icon-xxlarge {
  font-size: 64px;
}
.icon-xxxlarge {
  font-size: 78px;
}
